<template>
  <div class="card">
    <div class="card-header">
      <div class="row mt-3 ml-1 mr-1">
        <div class="col-md-8">
          <a-range-picker
            style="width: 100%"
            :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
            format="DD-MM-YYYY"
            :mode="['date', 'date']"
            :value="monthRangeData"
            @panelChange="handlePanelChange"
            @change="handlePanelChange"
          />
        </div>
        <div class="col-md-2">
          <json-csv
            class="ant-btn"
            style="width: 100%"
            :fields="fields"
            :labels="labels"
            :data="csvTypeDepenses"
            :name="'depenses.csv'"
            ><a-icon
              class="anticon mt-2"
              style="color: green"
              type="file-excel"
            /><span>CSV</span></json-csv
          >
        </div>
        <div class="col-md-2">
          <a-button
            a-button
            type="primary"
            style="width: 100%"
            @click="imprimer"
          >
            <a-icon type="printer" />{{ $t("action.imprimer") }}
          </a-button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-10 offset-md-1">
          <a-button @click="showModal" style="width: 100%"
            ><a-icon type="dollar" /> {{ $t("action.ajouter") }}
          </a-button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="d-flex flex-column justify-content-center">
        <h5 class="mb-3">
          <strong>{{ $t("menu.depenses") }}</strong>
        </h5>
      </div>
      <a-table
        @change="tableChanged"
        rowKey="_id"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :loading="loadingData"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="date" slot-scope="text, record">
          {{ moment(record.date).format("DD/MM/YYYY") }}
        </template>
        <template slot="label" slot-scope="text, record">
          <div key="label">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record._id, 'label')"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="tags" slot-scope="text, record">
          <a-tag color="red" v-if="record.status == 'cancelled'">{{
            $t("recette.annule")
          }}</a-tag>
        </template>
        <template slot="money" slot-scope="text, record">
          <div key="money">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="(e) => handleChange(e.target.value, record._id, 'money')"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="depenseType" slot-scope="text, record">
          <template v-if="!record.editable">
            {{ text }}
          </template>
          <a-select
            v-else
            style="width: 100%"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :default-value="record.depenseType._id"
            @change="
              (val) => {
                record.depenseType._id = val;
                record.depenseType.name = depense_types.find(
                  (item) => item._id === val
                ).name;
                record.depenseType.edited = true;
              }
            "
            :placeholder="$t('recette.typeDepense')"
            v-decorator="[
              'depenseType',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.depense'),
                  },
                ],
              },
            ]"
          >
            <a-select-option
              :value="item._id"
              v-for="item in depense_types"
              :key="item._id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="provider" slot-scope="text, record">
          <template v-if="!record.editable">
            <Router-link :to="'/fournisseur/' + record.provider._id">
              <span style="color: #4b7cf3; text-decoration: underline">{{
                text
              }}</span>
            </Router-link>
          </template>
          <a-select
            v-else
            style="width: 100%"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :default-value="record.provider._id"
            :placeholder="$t('recette.fournisseur')"
            @change="
              (val) => {
                record.provider._id = val;
                record.provider.fullName = providersList.find(
                  (item) => item._id === val
                ).fullName;
                record.provider.edited = true;
              }
            "
          >
            <a-select-option
              :value="item._id"
              v-for="item in providersList"
              :key="item._id"
            >
              {{ item.fullName }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="expandedRowRender" slot-scope="record">
          <p>
            {{ $t("recette.doneBy") }} :
            <a-tag color="blue"> {{ record.userName || "--" }}</a-tag>
          </p>
          <p v-if="record.status == 'cancelled'">
            {{ $t("recette.cancelledBy") }} :
            <a-tag color="red">{{ record.cancelledBy }}</a-tag>
          </p>
          <p v-if="record.status == 'cancelled'">
            {{ $t("recette.dateAnnulation") }} :
            <a-tag color="red">
              {{ moment(record.cancelationDate).format("DD/MM/YYYY") }}
            </a-tag>
          </p>
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div
            class="editable-row-operations"
            v-if="record.status != 'cancelled'"
          >
            <span v-if="record.editable">
              <a-button
                class="mr-1"
                size="small"
                @click="() => save(record._id)"
                >{{ $t("all.save") }}</a-button
              >
              <a-popconfirm
                :title="$t('all.sureToCancelAlert')"
                @confirm="() => cancel(record._id)"
              >
                <a-button size="small"> {{ $t("action.annuler") }} </a-button>
              </a-popconfirm>
            </span>
            <span v-else>
              <a-button
                type="primary"
                :disabled="editingKey !== ''"
                @click="() => edit(record._id)"
              >
                <a-icon
                  type="edit"
                  :disabled="editingKey !== ''"
                  @click="() => edit(record._id)"
                />{{ $t("action.modifier") }}
              </a-button>
            </span>
          </div>
        </template>
        <template slot="delete" slot-scope="text, record">
          <a-popconfirm
            v-if="record.status != 'cancelled'"
            :title="$t('all.sureToDelete')"
            @confirm="() => supp(record._id)"
          >
            <a-button type="danger">
              <a-icon type="delete" href="javascript:;" />{{
                $t("action.supprimer")
              }}
            </a-button>
          </a-popconfirm>
        </template>
        <a-button
          slot="print"
          type="primary"
          slot-scope="text, record"
          @click="
            () => {
              printOneDepense(record);
            }
          "
        >
          <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
        </a-button>
      </a-table>
      <div class="d-flex flex-column justify-content-center">
        <h5 class="mb-3">
          <strong>{{ $t("menu.depensesPersonnel") }}</strong>
        </h5>
      </div>
      <a-table
        :pagination="true"
        :data-source="dataEmp"
        :columns="columnsEmp"
        :loading="loadingEmp"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="month" slot-scope="text">{{
          moment(text).format("DD-MM-YYYY")
        }}</template>
        <template slot="bank" slot-scope="text, record">{{
          record.bank ? $t("all.oui") : $t("all.non")
        }}</template>
        <template slot="employee" slot-scope="text, record"
          >{{ record.employee.firstName }}
          {{ record.employee.lastName }}</template
        >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >Rechercher</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
        <a-button
          slot="print"
          type="primary"
          slot-scope="text, record"
          @click="
            () => {
              printOneSalariale(record);
            }
          "
        >
          <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
        </a-button>
      </a-table>

      <div class="d-flex flex-column justify-content-center">
        <h5 class="mb-3">
          <strong>Avoirs</strong>
        </h5>
      </div>
      <a-table
        :pagination="true"
        :data-source="dataAvoir"
        :columns="columnsAvoir"
        :loading="loadingAvoir"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="date" slot-scope="text, record">
          {{ moment(record.date).format("DD/MM/YYYY") }}
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >Rechercher</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
        <a-button
          slot="print"
          type="primary"
          slot-scope="text, record"
          @click="
            () => {
              printOneAvoir(record);
            }
          "
        >
          <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
        </a-button>
      </a-table>
    </div>
    <a-modal
      v-model="visibleModal"
      :title="$t('all.enterInformationBelow')"
      @cancel="cancelModal"
      :width="960"
      :height="1200"
      :footer="false"
    >
      <a-form :form="form" @submit="addDepense">
        <div class="row">
          <div class="col-md-6">
            <a-form-item :label="$t('bulletin.label')">
              <a-input
                :placeholder="$t('bulletin.label')"
                v-decorator="[
                  'label',
                  {
                    rules: [
                      { required: true, message: $t('all.requiredName') },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <a-form-item :label="$t('recette.montant')">
              <a-input
                :placeholder="$t('recette.montant')"
                type="number"
                step="0.1"
                v-decorator="[
                  'money',
                  {
                    rules: [{ required: true, message: $t('requis.montant') }],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item :label="$t('recette.datePaiement')">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'date',
                  {
                    initialValue: moment(Date.now()),
                    rules: [
                      {
                        required: true,
                        message: $t('requis.date'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <a-form-item :label="$t('recette.typeDepense')">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                :placeholder="$t('recette.typeDepense')"
                v-decorator="[
                  'depenseType',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.depense'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  :value="item._id"
                  v-for="item in depense_types"
                  :key="item._id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item :label="$t('recette.fournisseur')">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                :placeholder="$t('recette.fournisseur')"
                v-decorator="['provider']"
              >
                <a-select-option
                  :value="item._id"
                  v-for="item in providersList"
                  :key="item._id"
                >
                  {{ item.fullName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
        </div>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingAddBtn"
            :disabled="loadingAddBtn"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button key="back" @click="cancelModal" :disabled="loadingAddBtn">
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */
import { PageHeader } from "ant-design-vue";
import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
import axios from "axios";
const { AwesomeQR } = require("awesome-qr");

export default {
  components: {
    JsonCsv,
    "a-page-header": PageHeader,
  },
  created() {
    this.tableLoading = true;
    this.loadingAvoir = true;
    this.loadingEmp = true;
    this.loadingData = true;
    apiClient
      .post("/employee_payments/filter", {
        query: {},
      })
      .then((res) => {
        this.dataEmp = res.data;
      })
      .catch()
      .finally(() => {
        this.loadingEmp = false;
      });
    apiClient
      .post("/depense_types/filter", { query: { status: "active" } })
      .then((res) => {
        this.depense_types = res.data;
      })
      .catch();

    apiClient
      .post("/providers/filter", { query: { status: "active" } })
      .then((res) => {
        this.providersList = res.data;
      })
      .catch();
    apiClient
      .post("/depenses/filter", {
        query: {},
      })
      .then((res) => {
        res.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.data = res.data;
        this.rowData = res.data;
        this.filtredTable = res.data;
        this.cacheData = res.data.map((item) => ({ ...item }));
      })
      .catch()
      .finally(() => {
        this.loadingData = false;
      });
    apiClient
      .get("/reglement/avoirs")
      .then((res) => {
        this.dataAvoir = res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loadingAvoir = false;
      });
    this.tableLoading = false;
  },
  computed: {
    ...mapState(["settings"]),
    csvTypeDepenses() {
      const data = [...this.data];
      return data.map((item) => {
        return {
          TypeDeDepense: item.depenseType.name,
          date: moment(item.date).format("DD/MM/YYYY"),
          MontantTTC: item.money,
          fournisseur: item.provider ? item.provider.fullName : "--",
        };
      });
    },
  },
  data() {
    return {
      labels: {
        TypeDeDepense: "Type de depense",
        date: "date",
        MontantTTC: "Montant TTC",
      },

      fields: ["TypeDeDepense", "date", "MontantTTC", "fournisseur"],
      employeesList: [],
      positionsList: [],
      providersList: [],
      depense_types: [],
      monthRangeData: [],
      dataEmp: [],
      searchInput: null,
      rowData: [],
      data: [],
      dataAvoir: [],
      loadingAvoir: false,
      loadingData: false,
      loadingEmp: false,
      visibleModal: false,
      form: this.$form.createForm(this),
      tableLoading: false,
      columnsAvoir: [
        {
          title: this.$t("recette.numSerie"),
          dataIndex: "serial",
          key: "serial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "serial",
          },
        },
        {
          title: this.$t("recette.date"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.nomParent"),
          dataIndex: "clientName",
          key: "clientName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "clientName",
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
        },
        {
          title: this.$t("recette.montant"),
          dataIndex: "cancellation.money",
          key: "money",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "money",
          },
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
      ],
      columnsEmp: [
        {
          title: this.$t("etatPaiement.personnel"),
          dataIndex: "employee",
          key: "employee",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "employee",
          },
          onFilter: (value, record) =>
            record.employee.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("etatPaiement.salaire"),
          dataIndex: "salary",
          key: "salary",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "salary",
          },
          onFilter: (value, record) =>
            record.salary.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("etatPaiement.avance"),
          dataIndex: "loan",
          key: "loan",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "loan",
          },
          onFilter: (value, record) =>
            record.loan.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("etatPaiement.nbJours"),
          dataIndex: "dayNbr",
          key: "dayNbr",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "dayNbr",
          },
          onFilter: (value, record) =>
            record.dayNbr.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("etatPaiement.mois"),
          dataIndex: "month",
          key: "month",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "month",
          },
          onFilter: (value, record) =>
            record.month.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("etatPaiement.etatPaiement"),
          dataIndex: "paymentStatus",
          key: "paymentStatus",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "paymentStatus",
          },
          onFilter: (value, record) =>
            record.paymentStatus.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("etatPaiement.virementBancaire"),
          dataIndex: "bank",
          key: "bank",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "bank",
          },
          onFilter: (value, record) =>
            record.bank == (value == "oui" ? true : false),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
      ],
      columns: [
        {
          title: this.$t("bulletin.label"),
          dataIndex: "label",
          key: "label",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "label",
          },
          onFilter: (value, record) => {
            return record.label.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeDepense"),
          dataIndex: "depenseType.name",
          key: "depenseType",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "depenseType",
          },
          onFilter: (value, record) => {
            return record.depenseType.name
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.date"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "money",
          key: "money",
          scopedSlots: {
            customRender: "money",
          },
          sorter: (a, b) => a.money - b.money,
        },
        {
          title: this.$t("recette.fournisseur"),
          dataIndex: "provider.fullName",
          key: "provider",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "provider",
          },
          onFilter: (value, record) =>
            record.provider.fullName
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Tags",
          dataIndex: "tags",
          key: "tags",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "tags",
          },
          onFilter: (value, record) =>
            (record.status == "cancelled" ? this.$t("recette.annule") : "")
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "delete",
          scopedSlots: { customRender: "delete" },
          fixed: "right",
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
      ],
      moment,
      filtredTable: [],
      editingKey: "",
      loadingAddBtn: false,
    };
  },
  methods: {
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.find((item) => id === item._id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item._id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(id) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find((item) => id === item._id);
      const targetCache = newCacheData.find((item) => id === item._id);
      const toUpdate = {
        money: target.money,
        label: target.label,
      };
      if (target.depenseType.edited) {
        toUpdate.depenseType = target.depenseType._id;
        target.depenseType.edited = false;
      }
      if (target.provider.edited) {
        toUpdate.provider = target.provider._id;
        target.provider.edited = false;
      }
      apiClient
        .patch(`/depenses/${id}`, {
          data: toUpdate,
        })
        .then((res) => {
          this.$message.success(this.$t("success.depenseMSA"));
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
        })
        .catch((err) => this.$message.error(this.$t("error.depenseNonMSA")));

      this.editingKey = "";
    },
    cancel(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item._id)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => id === item._id)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    imprimer() {
      this.$gtag.event("Imp Dépenses", {
        event_category: "Impression PDF",
        event_label: "comptabilite:Dépenses",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      const pdf_wdith = doc.internal.pageSize.getWidth();
      doc.setFont("Amiri");
      var date = new Date();
      let filterText = "";
      if (this.monthRangeData[0])
        filterText =
          "De " +
          this.monthRangeData[0]._d.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
            day: "numeric",
          }) +
          " à " +
          this.monthRangeData[1]._d.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
            day: "numeric",
          });
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.text(60, 70, filterText);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      const array2 = [];
      let sommeHT = 0;
      let sommeTTC = 0;
      let sommeTTC2 = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(this.filtredTable[i].userName || "--");
        arr.push(this.filtredTable[i].label || "");
        arr.push(this.filtredTable[i].depenseType.name);
        arr.push(
          this.filtredTable[i].provider
            ? this.filtredTable[i].provider.fullName
            : "--"
        );
        arr.push(
          this.filtredTable[i].provider
            ? this.filtredTable[i].provider.phone
            : "--"
        );
        arr.push(moment(this.filtredTable[i].date).format("DD/MM/YYYY"));
        arr.push(this.filtredTable[i].money);
        if (this.filtredTable[i].status == "cancelled") {
          sommeTTC2 += parseInt(this.filtredTable[i].money);
          arr.push(
            moment(this.filtredTable[i].cancellationDate).format("DD/MM/YYYY")
          );
          arr.push(this.filtredTable[i].cancelledBy);
          array2.push(arr);
        } else {
          sommeTTC += parseInt(this.filtredTable[i].money);

          array.push(arr);
        }

        arr = [];
      }

      doc.setFontSize(15);
      doc.text(pdf_wdith / 2, 60, "Dépenses", { align: "center" });

      doc.autoTable({
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          [
            "Effectué par",
            "Libellé",
            "Type",
            "Fournisseur",
            "Téléphone",
            "Date",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          [
            "Total",
            {
              colSpan: 3,
              content: writtenNumber(sommeTTC, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            sommeTTC,
          ],
        ],
      });

      doc.setFontSize(15);
      doc.text(pdf_wdith / 2, doc.autoTableEndPosY() + 15, "Dépenses annulé", {
        align: "center",
      });

      doc.autoTable({
        startY: doc.autoTableEndPosY() + 30,
        head: [
          [
            "Effectué par",
            "Libellé",
            "Type",
            "Fournisseur",
            "Téléphone",
            "Date",
            "Montant TTC",
            "Date d'annulation",
            "Annulé par",
          ],
        ],
        styles: {
          fontSize: 9,
        },
        headStyles: {
          fontSize: 9,
        },
        body: array2,
        foot: [
          [
            "Total",
            {
              colSpan: 3,
              content: writtenNumber(sommeTTC2, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            sommeTTC2,
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Dépenses.pdf");
    },
    printOneDepense(record) {
      this.$gtag.event("Imp one depense", {
        event_category: "Impression PDF",
        event_label: "comptabilite:Dépenses",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = "Dépense :  " + record.depenseType.name;

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      // doc.text(20, 70, "Nom et Prénom :" + record.nomClient);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      arr.push(record.label || "");
      arr.push(record.depenseType.name);
      arr.push(moment(record.date).format("DD/MM/YYYY"));
      arr.push(record.provider ? record.provider.fullName : "--");
      arr.push(parseFloat(record.money).toFixed(2));
      array.push(arr);

      arr = [];

      arr.push({
        colSpan: 4,
        content: "Total",
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      arr.push({
        content: parseFloat(record.money).toFixed(2),
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      array.push(arr);

      arr = [];

      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Libellé", "Type", "Date", "Fournisseur", "Montant TTC"]],
        body: array,
        foot: [
          [
            {
              colSpan: 5,
              content:
                "Le montant total est " +
                writtenNumber(record.money, { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save(titre + ".pdf");
    },
    async printOneAvoir(record) {
      this.$gtag.event("Imp one avoir", {
        event_category: "Impression PDF",
        event_label: "comptabilite:Dépenses",
        value: 1,
      });

      this.loadingPrintOneAvoir = true;
      const tva = 0.07;
      const fiscal = parseFloat(process.env.VUE_APP_FISCAL);
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = record.bill
        ? "Avoir - Facture N. " + record.serial.split(":")[1]
        : "Avoir - Reçue N. " + record.serial.split("/")[1];
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(20, 70, "Nom et Prénom : " + record.clientName);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];

      let onBillReduction = record.onBillReduction
        ? record.onBillReduction / 100
        : 0;

      console.log(record.student);
      if (record.onInscription) {
        arr.push(record.student.lastName + " " + record.student.firstName);
        arr.push("Inscription ");
        arr.push(record.name);
        arr.push(
          record.bill
            ? record.payment.inscription - record.payment.inscription * tva
            : record.payment.inscription
        );
        arr[arr.length - 1] -= arr[arr.length - 1] * onBillReduction;
        array.push(arr);
        arr = [];
      }
      if (record.months) {
        Object.keys(record.months).forEach((k) => {
          arr.push(record.student.lastName + " " + record.student.firstName);

          let paymentDate = new Date(k);
          let monthName = paymentDate.toLocaleString("fr-FR", {
            month: "long",
          });
          arr.push(monthName);
          arr.push(record.name);

          arr.push(
            record.bill
              ? record.payment.monthly - record.payment.monthly * tva
              : record.payment.monthly
          );
          arr[arr.length - 1] -= arr[arr.length - 1] * onBillReduction;

          array.push(arr);
          arr = [];
        });
      }

      let money = 0;
      if (record.onInscription) {
        const inscriptionFees = parseFloat(record.payment.inscription);
        money = inscriptionFees;
      }
      if (record.months) {
        const nbMonth = Object.keys(record.months).length;
        const monthFees = nbMonth * record.payment.monthly;
        money = money + monthFees;
      }
      record.onBillReduction = record.onBillReduction
        ? record.onBillReduction
        : 0;
      money = money - (money * onBillReduction) / 100;

      const montantHT = money - money * tva;
      const montantTTC = money + (record.bill ? fiscal : 0);

      let rowData = [montantHT, montantTTC - montantHT, fiscal, montantTTC];

      let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
      for (let i = 0; i < 4; i++) {
        if (!record.bill) {
          i = 3;
          rowDataText[i] = "Total";
        }
        arr.push({
          colSpan: 3,
          content: rowDataText[i],
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: parseFloat(rowData[i]).toFixed(2),
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Enfant", "Frais", "Désignation", "Montant"]],
        body: array,
        foot: [
          [
            {
              colSpan: 4,
              content:
                "Le montant total est " +
                writtenNumber(montantTTC, { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });

      let finalY = doc.lastAutoTable.finalY;

      let logo = "data:image/jpeg;base64, ";

      await axios
        .get("favicon.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          logo += Buffer.from(response.data, "binary").toString("base64");
        });

      let qrDataDetail =
        "avoir" +
        "." +
        record.reglementId +
        "." +
        record.serial +
        "." +
        this.settings.activeSchoolarYear;

      const qrData = btoa(qrDataDetail);

      new AwesomeQR({
        text: qrData,
        logoImage: logo,
        size: 500,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: true,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        bindElement: true,
        backgroundColor: "#FFFFFF",
      })
        .draw()
        .then((dataURL) => {
          //page numbering
          const pages = doc.internal.getNumberOfPages();
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;

          doc.setFontSize(8); //Optional

          for (let j = 1; j < pages + 1; j++) {
            let horizontalPos = pageWidth / 2;
            let verticalPos = pageHeight - 10;
            doc.setPage(j);
            doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
              align: "center",
            });
            doc.addImage(dataURL, "JPEG", 181, verticalPos - 9, 15, 15);
          }
          //end page numbering
          this.loadingPrintOneAvoir = true;
          doc.save(titre + ".pdf");
        });
    },
    printOneSalariale(record) {
      this.$gtag.event("Imp one salaire", {
        event_category: "Impression PDF",
        event_label: "comptabilite:Dépenses",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = "Dépense salariale :  ";

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.setFontSize(15);
      doc.text(80, 60, titre);

      const employeeFullname = `${record.employee.firstName} ${record.employee.lastName}`;

      doc.text(20, 70, "Nom et Prénom : " + employeeFullname);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];

      arr.push(employeeFullname);
      arr.push(moment(record.month).format("MMMM YYYY"));
      arr.push(record.loan);
      arr.push(record.dayNbr);
      arr.push(record.paymentStatus);
      arr.push(record.bank ? "Oui" : "Non");
      arr.push(parseFloat(record.salary).toFixed(2));

      array.push(arr);

      arr = [];

      arr.push({
        colSpan: 6,
        content: "Total",
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      arr.push({
        content: parseFloat(record.salary).toFixed(2),
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      array.push(arr);

      arr = [];

      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          [
            "Employée",
            "Mois",
            "Avance",
            "Nombre de jours",
            "Etat de paiement",
            "Virement bancaire",
            "Montant",
          ],
        ],
        body: array,
        foot: [
          [
            {
              colSpan: 7,
              content:
                "Le montant total est " +
                writtenNumber(record.salary, { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering

      doc.save(`${titre} ${employeeFullname}` + ".pdf");
    },
    showModal() {
      this.visibleModal = true;
    },
    cancelModal() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    addDepense(e) {
      e.preventDefault();

      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingAddBtn = true;
          apiClient
            .put("/depenses", { data: values })
            .then(() => {
              this.$emit("refrech");
            })
            .finally(() => (this.loadingAddBtn = false));
        }
      });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handlePanelChange(value) {
      this.monthRangeData = value;
      if (value)
        this.data = this.rowData.filter((elem) => {
          const start = moment(value[0]).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          const end = moment(value[1]).set({
            hour: 24,
            minute: 59,
            second: 59,
            millisecond: 99,
          });
          return moment(elem.date).isBetween(start, end, undefined, "[]");
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    supp(key) {
      apiClient
        .patch("/depenses/" + key, {
          data: {
            status: "cancelled",
          },
        })
        .then(() => {
          this.$message.success(this.$t("success.depenseSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item._id !== key);
          this.filtredTable = this.filtredTable.filter(
            (item) => item._id !== key
          );
        })
        .catch(() => this.$message.error(this.$t("error.depenseNonSupp")));
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editable-row-operations a {
  margin-right: 8px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
